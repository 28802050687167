<template>
  <div class="newsMooMall">
    <Navbar
      :isSearch="isSearch"
      :isShare="isShare"
      :isRedirectBack="isRedirectBack"
    >
      <span> {{ $t('navBarMoomall.moomallGuide') }}</span>
    </Navbar>
    <v-main>
      <CardNewsList />
    </v-main>
    <Footer></Footer>
  </div>
</template>

<script>
import CardNewsList from '@/components/Main/News/CardNewsList.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'newsMooMall',
  components: {
    CardNewsList,
  },
  mounted() {
    const {
      pacerLevel = 0,
      mall_member_token_key,
      userMobile,
    } = this.$route.query
    pacerLevel && this.setPacerLevel({ pacerLevel: +pacerLevel })
    mall_member_token_key &&
      this.setMallMemberTokenKey({ mallMemberTokenKey: mall_member_token_key })
    userMobile && this.setUserMobile({ userMobile: userMobile })
  },
  data() {
    return {
      isSearch: true,
      isShare: true,
      isRedirectBack: true,
    }
  },
  methods: {
    ...mapMutations({
      setPacerLevel: 'PacerStore/setPacerLevel',
      setMallMemberTokenKey: 'PacerStore/setMallMemberTokenKey',
      setUserMobile: 'PacerStore/setUserMobile',
    }),
  },
}
</script>

<style scoped>
.newsMooMall {
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
}
</style>

<template>
  <v-container class="pt-0">
    <v-row dense>
      <v-col cols="12">
        <v-row dense>
          <v-col
            cols="12"
            lg="3"
            md="6"
            sm="6"
            v-for="item in News"
            :key="item.id"
          >
            <v-card
              v-if="item.is_pin"
              id="rounded-card-news"
              class="mx-auto mb-n2"
              color="#FBE7E7"
              elevation="0"
              max-width="480"
              height="45"
            >
              <v-list-item>
                <v-list-item-icon class="mr-0">
                  <BaseIcon
                    class="mt-n2"
                    nameIcon="icon-new-feed-new"
                    height="20"
                    cssIcon="red--text"
                  />
                </v-list-item-icon>
                <v-list-item-subtitle class="mt-n2"
                  >คอร์สเรียนใหม่</v-list-item-subtitle
                >
              </v-list-item>
              <p class="mt-n2">คอร์สเรียนใหม่</p>
            </v-card>
            <v-card
              id="rounded-card"
              class="mx-auto mb-5"
              elevation="0"
              max-width="480"
              @click.native="selectCard(item)"
            >
              <BaseImage
                :pathImage="item.image_id"
                cssImage="mx-auto rounded-image-news"
              />

              <v-card-text>
                <div
                  class="black--text text-title"
                  v-html="
                    $i18n.locale === 'th_TH' ? item.title_th : item.title_en
                  "
                ></div>
              </v-card-text>

              <v-card-actions>
                <BaseIcon nameIcon="Icon-calendar" cssIcon="ma-2" />

                <div
                  class="subtitle-text grey--text text--darken-2"
                  v-text="created_at(item.created_at)"
                ></div>
                <v-chip
                  class="mx-2"
                  color="#D11515"
                  small
                  dark
                  v-if="item.is_new && timeOut(item.created_at) > 0"
                >
                  {{ $t('mooMallNews.new') }}
                </v-chip>
                <v-spacer></v-spacer>
                <BaseButtonShare
                  icon="icon-share-nav-new"
                  :isShowText="true"
                  css="mr-2"
                  :postfix="`content-detail/${item.id}`"
                  v-if="webShareApiSupported"
                />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <scroll-loader
      :loader-method="loading"
      :loader-disable="disable"
      loader-color="#D60000"
    >
    </scroll-loader>
  </v-container>
</template>

<script>
import NewsService from '@/services/NewsService'

export default {
  name: 'News',
  data() {
    return {
      News: [],
      disable: false,
      page: 1,
      pageSize: 10,
      totalItems: 0,
    }
  },
  computed: {
    webShareApiSupported() {
      return navigator.share
    },
  },
  methods: {
    selectCard(item) {
      this.$router.push({ name: 'NewsDetail', params: { id: item.id } })
    },
    created_at(local) {
      var date = new Date(local)
      var dateFormat = date.toLocaleDateString(
        this.$i18n.locale === 'th_TH' ? 'th-TH' : 'en-EN',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        },
      )
      return dateFormat
    },
    timeOut(expireTime) {
      const timeOutExpire = new Date(expireTime.replace(' ', 'T'))
      const expTime = 3 * 24 * 60 * 60 * 1000 // day * 24 * 60 * 60 * 1000
      const ExpireTime = timeOutExpire.getTime() + expTime
      const timeout = ExpireTime - new Date().getTime()
      return timeout
    },
    loading() {
      NewsService.getNews({ page: this.page++, limit: this.pageSize })
        .then(res => {
          if (res.status === 200) {
            this.News.push(...res.data.items)
            this.totalItems = res.data.meta.totalItems

            // Stop scroll loading...
            if (this.News.length === this.totalItems) {
              this.disable = true
            }
          }
        })
        .catch(err => {
          this.$swal
            .fire({
              icon: 'error',
              title: `${err.message}`,
              showDenyButton: false,
            })
            .then(result => {
              if (result.isConfirmed) {
                return
              }
            })
        })
    },
  },
}
</script>

<style scoped>
#rounded-card {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#rounded-card-news {
  border-radius: 10px;
}
.subtitle-text {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 28px;
}

.text-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 44px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media only screen and (max-width: 360px) {
  .subtitle-text {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 320px) {
  .subtitle-text {
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 28px;
  }
}
</style>
